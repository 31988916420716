export const servicePaymentMixin = {
  data() {
    return {
      loading: true,
      alertMsgs: [],
      showAlertModal: false,
      payer: null,
      typePaymentProviderSelected: 1,
      typePaymentProviderOptions: [
        { item: 1, name: "Comisión" },
        { item: 2, name: "Porcentaje" }
      ],
      payment: null,
      plans: null,
      planInfo: null,
      requestedService: [],
      flagErr: false,
      errMsg: "",
      order: [],
      uuidValue: null,
      collectionFee: 0,
      displacementFee: 0,
      cancellationFee: 0,
      exclusivityFee: 0,
      clientSchedule: null,
      flagSchedule: false,
      showOrderModal: false,
      clientCreditsToApply: [],
      activePayment: false ,
      paymentDTO: null,
      paymentResumen: null,
      enablePayment: false,
    };
  },
  computed: {
    orderResumen() {
      //total price calculate to pay
      if (this.order.length > 0) {
        let totalPrice = 0;
        if(this.payment.methodSelected && this.payment.methodSelected == 'Efectivo')
          this.payment.collectionFee = this.collectionFee;
        this.order.forEach(orderItem => {
          let numberServices = orderItem.pedido.cantidad;
          let priceUnt = orderItem.pedido.precio_und;
          // let subTotal = numberServices * priceUnt + orderItem.pedido.tarifa_recaudo;
          //TO DO - collection fee
          let subTotal = (numberServices * priceUnt) +  (this.payment.collectionFee ? this.payment.collectionFee : 0);
          totalPrice = totalPrice + subTotal;
        });
        return totalPrice;
      } else {
        return 0;
      }
    },
    // orderResumen() {
    //   //total price calculate to pay
    //   if (this.requestedService.length > 0) {
    //     let totalPrice = 0;

    //     if(this.payment.methodSelected && this.payment.methodSelected == 'Efectivo')
    //       this.payment.collectionFee = this.collectionFee;

    //     this.requestedService.forEach(item => {
    //       let numberServices = item.cantidad;
    //       let priceUnt = item.precio_und;
    //       // let subTotal = numberServices * priceUnt + orderItem.pedido.tarifa_recaudo;
    //       //TO DO - collection fee
    //       let subTotal = (numberServices * priceUnt) +  (this.payment.collectionFee ? this.payment.collectionFee : 0);
    //       totalPrice = totalPrice + subTotal;
    //     });
    //     return totalPrice;
    //   } else {
    //     return 0;
    //   }
    // },
    // orderResumen() {
    //   //total price calculate to pay
    //   if (this.order.length > 0) {
    //     let totalPrice = 0;

    //     if(this.payment.methodSelected && this.payment.methodSelected == 'Efectivo')
    //       this.payment.collectionFee = this.collectionFee;

    //     this.order.forEach(item => {
    //       let numberServices = item.pedido.cantidad;
    //       let priceUnt = item.pedido.precio_und;
    //       // let subTotal = numberServices * priceUnt + orderItem.pedido.tarifa_recaudo;
    //       //TO DO - collection fee
    //       let subTotal = (numberServices * priceUnt) +  (this.payment.collectionFee ? this.payment.collectionFee : 0);
    //       totalPrice = totalPrice + subTotal;
    //     });
    //     return totalPrice;
    //   } else {
    //     return 0;
    //   }
    // },
    credit() {
      if (this.payment.amount == 0 || this.payment.amount < this.orderResumen) {
        return 0;
      }
      return this.payment.amount - this.orderResumen;
    },
    
  }

};