const times = [
  { value: 1, label: "05:00" },
  { value: 2, label: "05:15" },
  { value: 3, label: "05:30" },
  { value: 4, label: "05:45" },

  { value: 5, label: "06:00" },
  { value: 6, label: "06:15" },
  { value: 7, label: "06:30" },
  { value: 8, label: "06:45" },

  { value: 9, label: "07:00" },
  { value: 10, label: "07:15" },
  { value: 11, label: "07:30" },
  { value: 12, label: "07:45" },

  { value: 13, label: "08:00" },
  { value: 14, label: "08:15" },
  { value: 15, label: "08:30" },
  { value: 16, label: "08:45" },

  { value: 17, label: "09:00" },
  { value: 18, label: "09:15" },
  { value: 19, label: "09:30" },
  { value: 20, label: "09:45" },

  { value: 21, label: "10:00" },
  { value: 22, label: "10:15" },
  { value: 23, label: "10:30" },
  { value: 24, label: "10:45" },

  { value: 25, label: "11:00" },
  { value: 26, label: "11:15" },
  { value: 27, label: "11:30" },
  { value: 28, label: "11:45" },

  { value: 29, label: "12:00" },
  { value: 30, label: "12:15" },
  { value: 31, label: "12:30" },
  { value: 32, label: "12:45" },

  { value: 33, label: "13:00" },
  { value: 34, label: "13:15" },
  { value: 35, label: "13:30" },
  { value: 36, label: "13:45" },

  { value: 37, label: "14:00" },
  { value: 38, label: "14:15" },
  { value: 39, label: "14:30" },
  { value: 40, label: "14:45" },

  { value: 41, label: "15:00" },
  { value: 42, label: "15:15" },
  { value: 43, label: "15:30" },
  { value: 44, label: "15:45" },

  { value: 45, label: "16:00" },
  { value: 46, label: "16:15" },
  { value: 47, label: "16:30" },
  { value: 48, label: "16:45" },

  { value: 49, label: "17:00" },
  { value: 50, label: "17:15" },
  { value: 51, label: "17:30" },
  { value: 52, label: "17:45" },

  { value: 53, label: "18:00" },
  { value: 54, label: "18:15" },
  { value: 55, label: "18:30" },
  { value: 56, label: "18:45" },

  { value: 57, label: "19:00" },
  { value: 58, label: "19:15" },
  { value: 59, label: "19:30" },
  { value: 60, label: "19:45" },
  
  { value: 61, label: "20:00" }
];

module.exports = times;
